body {
  background-color: rgb(233, 233, 233);
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.App {
  text-align: center;
}

.emoji-container {
  display: inline-grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}

.emoji-box {
  background-color: white;
  color: rgb(80, 80, 80);

  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;

  border: none;

  border-radius: 10px;
  padding: 12px;
  width: 70px;
  height: 70px;
  position: relative;
}

.emoji-box:hover {
  cursor: pointer;
  transform: translate3d(0px, -1px, 0px);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px;
}

.emoji-box:active {
  cursor: pointer;
  transform: translate3d(0px, 1px, 0px);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 19px 43px, rgba(0, 0, 0, 0.09) 0px 4px 11px;
}

.emoji-box:disabled{
  pointer-events: none;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  transform: scale(0.95);
  opacity: 0.2;
}



.emoji-box.selected {
  background: #a9e8f4;
  -webkit-box-shadow: inset 0px 0px 5px #5b95b4;
     -moz-box-shadow: inset 0px 0px 5px #5b95b4;
          box-shadow: inset 0px 0px 5px #5b95b4;
   outline: none;
}

.emoji-box img {
  width: 50px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.output-image {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.loading-image {
  transform: scale(0.95);
}

.not-selected-image {
  filter: grayscale(100%);
  transform: scale(0.85);
}